import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import 'bootstrap';
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'

import PrimeVue from 'primevue/config';
Vue.use(PrimeVue);
import "primevue/resources/primevue.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primeicons/primeicons.css"
Vue.config.productionTip = false;


Vue.use(VueAwesomeSwiper, /* { default options with global component } */
)
new Vue({
    router,
    render: (h) => h(App)
}).$mount("#app");
