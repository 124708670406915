<template>
  <div>
    <Header />
    <div class="jumbotron-wrapper color" id="loading-background"></div>
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  mounted() {
    const loadingBackground = document.querySelector("#loading-background");
    loadingBackground.style.height =
      document.documentElement.clientHeight + "px";
    this.$router.onReady(function () {
      document.querySelector("#loading-background").style.display = "none";
    });
  },
};
</script>

<style lang="scss">
html {
  min-width: 20rem;
}
body::-webkit-scrollbar {
  display: none;
}
@keyframes cover-title {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  33% {
    opacity: 0;
    transform: translateY(30px);
  }
  66% {
    opacity: 0.4;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.cover-text > * {
  font-family: "sans-serif";
}
.cover-text {
  color: white;
  text-shadow: 0 0 14px rgb(0 0 0 / 35%);
  z-index: 1;
}
.cover-text h2 {
  font-size: 2.5rem !important;
  line-height: normal;
}
.cover-text h1 {
  font-size: 3.5rem !important;
  line-height: normal;
}
section {
  height: 100%;
  min-height: 25rem;
}
body > section:nth-child(even) {
  background: $--ft-white;
  color: $--ft-dark-gray;
}
body > section:nth-child(odd) {
  background: white;
  color: $--ft-dark-gray;
}

.product_media {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;
}
.product_icon {
  padding: 0.66667rem;
  border-radius: 0.35rem;
  background: $--ft-secondary-2;
  text-align: center;
  display: inline-block;
  color: $--ft-white;
  font-size: 2rem;
  line-height: 1;
  min-width: 53px;
}

.animation {
  animation: cover-title 0.5s linear 0s 1;
}
.jumbotron-wrapper.color {
  background: $--ft-linear-gradient;
}
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/about/banner.jpg");
  background-size: cover;
  background-position: 50% 55%;
}
.jumbotron {
  padding-top: 64px;
  height: 400px;
}
.background {
  background-size: 480px 360px;
  background-position: 85% 70%;
  background-repeat: no-repeat;
}
.jumbotron img.product-icon {
  filter: drop-shadow(4px 4px 1px black);
  position: absolute;
  width: 25rem;
  right: 10rem;
  top: 5rem;
}

.cursor-default {
  cursor: default !important;
}

.hr {
  height: 3px;
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-image: linear-gradient(to right, $--ft-primary-1, transparent);
}

@media screen and (max-width: map-get($container-max-widths,sm)) {
}
.logo_50 {
  height: 50px;
  width: 50px;
}

@media screen and (max-width: map-get($container-max-widths, lg)) {
  .jumbotron img.product-icon {
    display: none;
  }
}
</style>
